<template>
  <b-modal
    id="generic-attach-file-modal"
    :title="'Adicionar novo ' + fileType"
    hide-header
    hide-footer
    centered
    @shown="onShow"
    size="lg"
  >
    <div class="modal-header d-flex flex-row justify-content-between">
      <p class="modal-title">
        {{ file?.id ? 'Editar ' : 'Adicionar novo ' }}
        {{ fileType }}
      </p>
      <Close class="icon" @click="closeModal" />
    </div>
    <div class="modal-body">
      <div class="form-group md-content">
        <label for="file">Nome do {{ fileType }} <span class="help optional">(opcional)</span></label>
        <input
          id="file"
          class="form-control"
          placeholder="Digite o nome do arquivo..."
          v-model="file.filename"
        />
      </div>
      <div class="file-atach md-content">
        <div
          class="file-container"
          :class="{ 'without-file': !file.file && !file.id }"
          @dragover.prevent
          @drop.prevent
        >
          <div @drop="dragFile" class="drag-area" v-if="!file.file && !file.id">
            <div class="set-file" @drop="addFile" @dragover.prevent @drop.prevent @click="addFile(null, file.id)">
              <p>Arraste os arquivos aqui</p>

              <div class="separator">
                <HorizontalLine />
                ou
                <HorizontalLine />
              </div>

              <label for="add-file-input" class="add-btn"
              >Anexar arquivos</label
              >
              <input
                id="add-file-input"
                type="file"
                accept=".jpg, .png, .pdf, .jpeg, .jps, .tiff"
                @change="addFile"
              />
            </div>
          </div>
          <div class="file" v-else>
            <div>
              <Pdf v-if="file.file?.type === 'application/pdf'" />
              <Files v-else />
              {{ file.file?.name || file?.filename }}
            </div>
            <Close v-if="!file.id" class="close" @click="removeFile" />
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <b-button v-if="!file.file && !file.id" variant="outline-primary" @click="closeModal">
          Voltar
        </b-button>
        <div v-else>
          <b-button
            variant="outline-danger"
            class="mr-2"
            @click="closeModal"
          >
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            @click="onSave"
          >
            Salvar {{ fileType }}
          </b-button>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    HorizontalLine: () => import('@/assets/icons/line.svg'),
    Pdf: () => import('@/assets/icons/pdf.svg'),
    Files: () => import('@/assets/icons/files.svg')
  },
  props: {
    fileType: { type: String, required: true },
    editingFile: { type: Object, default: null }
  },

  data() {
    return {
      clinic: JSON.parse(localStorage.getItem('clinic')),
      progress: null,
      error: false,
      file: this.defaultAttachedFile()
    }
  },

  methods: {
    onShow() {
      if (this.editingFile && !(this.editingFile instanceof PointerEvent)) {
        this.file = this.editingFile
      } else {
        this.file = this.defaultAttachedFile()
      }
    },
    removeFile() {
      this.progress = null
      this.error = false
      this.file.file = null
    },
    addFile(e) {
      this.progress = null
      this.error = false
      this.file.file = e?.target.files[0] || null
    },
    async onSave() {
      this.$emit('saveFile', this.file)
      this.$bvModal.hide('generic-attach-file-modal')
    },
    defaultAttachedFile() {
      return {
        id: null,
        file: null,
        filename: null,
        path: null,
        created_at: null,
        updated_at: null
      }
    },
    dragFile(e) {
      this.progress = null
      this.error = false
      const file = e.dataTransfer.files[0]
      const fileType = file.type
      this.fileExtension = fileType.split('/')[1]
      let nameExt = file.name.match(/(?:\.)([0-9a-z]+)$/i)
      nameExt = nameExt.length > 1 ? nameExt[1] : ''
      if (![
        'pdf', 'jpg', 'jpeg', 'png', 'jps',
        'application/pdf', 'image/jpg',
        'image/jpeg', 'image/png', 'tiff'
      ].includes(fileType || nameExt)) {
        return this.$toast.error('Formato de arquivo não suportado!')
      }
      this.file.file = e.dataTransfer.files[0]
    },
    closeModal() {
      this.$bvModal.hide('generic-attach-file-modal')
    },
  },
  watch: {
    editingFile: {
      deep: true,
      handler: function(newValue) {
        if (newValue) {
          this.file = newValue
        } else {
          this.file = this.defaultAttachedFile()
        }
      }
    }
  }
}
</script>

<style lang="scss">
#generic-attach-file-modal {
  .modal-content {
    .modal-header {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 24px !important;

      .modal-title {
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        color: var(----type-active);
      }

    }

    .icon {
      width: 24px;
      cursor: pointer;

      &.close {
        fill: var(--neutral-500);
      }

      &.download {
        stroke: var(--neutral-500);
        fill: var(--neutral-500);
      }
    }

    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .title-body-content {
        color: var(--dark-blue);
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
      }

      .file-name {
        background: var(--neutral-000);
        border: 1px solid #c6ceeb;
        box-sizing: border-box;
        border-radius: 8px;
        height: 56px;
        outline: none;
        text-indent: 16px;
        color: var(--type-active);
        margin: 8px 0 24px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
      }

      .optional {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 130%;
        color: var(--type-placeholder);
      }

      .file-inf {
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;

        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          color: var(--dark-blue);
          margin: 5px 0px;
        }

        .file-name {
          height: 38px;
          margin-bottom: 5px;
          padding: 10px;
          border: 1px solid var(--neutral-300);
          box-sizing: border-box;
          border-radius: 8px;
          font-family: Nunito Sans;
          font-style: normal;
          color: var(--type-active);
        }

        .file-name:focus {
          outline: none !important;
        }

        .file-type {
          width: 20px;
          height: 20px;
          margin-right: 5px;
          cursor: pointer;
        }
      }

      .file-atach {
        .file-container {
          height: 100px;
          background: transparent;
          border-top: 1px solid var(--neutral-200);
          border-bottom: 1px solid var(--neutral-200);

          &.without-file {
            height: 160px;
            border: 1px dashed var(--blue-500);
            box-sizing: border-box;
            border-radius: 8px;
            background: var(--neutral-100);
          }

          .drag-area {
            width: 100%;
            height: 100%;
            padding: 20px;
            font-weight: bold;
            font-size: 16px;
            line-height: 130%;
            color: var(--type-active);
            text-align: center;

            .separator {
              margin: 12px 0px;
            }

            .add-btn {
              background: var(--blue-700);
              border-radius: 8px;
              color: var(--neutral-000);
              padding: 8px 15px;
              cursor: pointer;

              font-family: Nunito Sans;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;
              letter-spacing: 0em;
              text-align: left;
            }

            #add-file-input {
              display: none;
            }

          }

          .file {
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: space-between;
            align-content: center;

            svg {
              width: 42px !important;
              height: 42px !important;
            }

            .close {
              width: 20px !important;
              height: 20px !important;
            }
          }
        }
      }

      .save-file {
        float: right;
        padding: 15px;
        margin-top: 20px;
        color: var(--neutral-000);
        background: var(--blue-500);
        border-radius: 8px;
      }
    }

    .modal-footer {
      .modal-footer-content {
        display: flex;
        justify-content: right;
        margin: 24px;

        button {
          width: auto;
          height: auto;
          padding: 14px 16px;
          font-weight: 600;
          font-size: 18px;
          line-height: 28px;
          border-radius: 8px;
          background-color: var(--blue-500);
          color: var(--neutral-000);
        }
      }
    }

    .md-content {
      padding: 30px 20px 10px 20px;
    }
  }
}
</style>
